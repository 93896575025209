import React from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
//import StoreIcon from "@mui/icons-material/Store";
//import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LogoutIcon from "@mui/icons-material/Logout";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useNavigate } from "react-router-dom";
import { logout } from "./auth";

const drawerWidth = 240;

const SideDrawer = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          borderTopRightRadius: 50,
          borderBottomRightRadius: 50,
          boxShadow: "0px 0px 8px 0px rgb(0 0 0 / 25%)",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar sx={{ justifyContent: "center", minHeight: "90px !important" }}>
        <img src="/images/miminko_usa.png" alt="fl logo" width="190px" />
      </Toolbar>
      <Divider />
      <List>
        <ListItem key="Inventory" disablePadding>
          <ListItemButton onClick={goToHome}>
            <ListItemIcon>
              <InventoryIcon sx={{ color: "#4e4e4e" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: "#4e4e4e" }} primary="Inventory" />
          </ListItemButton>
        </ListItem>
        {/* <ListItem key="Orders" disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <ShoppingCartIcon sx={{ color: "#4e4e4e" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: "#4e4e4e" }} primary="Orders" />
          </ListItemButton>
        </ListItem>
        <ListItem key="Store" disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <StoreIcon sx={{ color: "#4e4e4e" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: "#4e4e4e" }} primary="Store" />
          </ListItemButton>
        </ListItem> */}
      </List>
      <Divider />
      <List>
        <ListItem key="Settings" disablePadding>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon sx={{ color: "#4e4e4e" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: "#4e4e4e" }} primary="Log Out" />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default SideDrawer;
