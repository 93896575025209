import React from "react";
import Box from "@mui/material/Box";
// import AppBar from "@mui/material/AppBar";
import SideDrawer from "./SideDrawer";
import { useEffect, useState, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "./auth";

function changeToNumber(params) {
  if (params.row.Inventory) {
    return Number(params.row.Inventory);
  } else {
    return 0;
  }
}
const columns = [
  {
    field: "Image",
    headerName: "Image",
    width: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      if (params.value) {
        return (
          <img
            src={params.value}
            alt="product img"
            style={{ width: "90px", padding: "10px" }}
          />
        );
      }
    },
  },
  {
    field: "SKU",
    headerName: "Product SKU",
    width: 220,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Inventory",
    headerName: "Inventory",
    width: 120,
    editable: true,
    headerAlign: "center",
    align: "center",
    valueGetter: changeToNumber,
  },
  {
    field: "Model Number",
    headerName: "Model #",
    width: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Color Code",
    headerName: "Color Code",
    width: 110,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Size",
    headerName: "Size",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Color",
    headerName: "Color",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Batch Number",
    headerName: "Batch #",
    width: 120,
    align: "center",
    headerAlign: "center",
  },
];

const Home = () => {
  const [rowData, setRowData] = useState([]);

  const [snackbar, setSnackbar] = useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }
  });

  const processRowUpdate = (newRow) => {
    fetch(
      "https://9g0ogdkihf.execute-api.us-east-1.amazonaws.com/prod/products",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sku: newRow.SKU, quantity: newRow.Inventory }),
      }
    ).then(
      setSnackbar({
        children: "User successfully saved",
        severity: "success",
      })
    );

    return newRow;
  };

  const handleProcessRowUpdateError = useCallback((error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  useEffect(() => {
    fetch(
      "https://9g0ogdkihf.execute-api.us-east-1.amazonaws.com/prod/products"
    )
      .then((res) => res.json())
      .then((result) => setRowData(result));
  }, []);

  return (
    <Box sx={{ bgcolor: "#F5F6F8", display: "flex", height: "100vh" }}>
      <SideDrawer />
      <Box component="main" sx={{ width: "100%", padding: 5 }}>
        <DataGrid
          rows={rowData}
          getRowHeight={() => "auto"}
          columns={columns}
          pageSize={100}
          align="center"
          rowsPerPageOptions={[100]}
          disableSelectionOnClick
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          experimentalFeatures={{ newEditingApi: true }}
          getRowId={(row) => row.SKU}
          sx={{
            border: "none",
            boxShadow: 2,
            backgroundColor: "white",
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#ddb4b2",
              color: "white",
              fontWeight: "bold",
            },
          }}
        />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
    </Box>
  );
};

export default Home;
